import { MouseEvent, useCallback, useEffect, useState } from "react";
import { Box, Slider, styled, Tooltip } from "@mui/material";
import { useIntervalEffect } from "@react-hookz/web";
import {
  Card,
  DatePicker,
  DatePickerValue,
  SearchSelect,
  SearchSelectItem,
  Switch,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import axios from "axios";
import axiosRetry from "axios-retry";

import { EnvironmentTab } from "./components/EnvironmentTab";
import RegionCard from "./components/RegionCard";
import { convertNumberToTime, convertTimeToNumber, debounce } from "./lib/utils";
import { CombinedData, Environment } from "./utils/types";

const DEFAULT_COUNTER = 10;

axiosRetry(axios, { retries: 15, retryDelay: axiosRetry.exponentialDelay });

interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = (props) => {
  const { children, open, value } = props;

  const customValue = ` ${convertNumberToTime(value)}`;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={customValue}>
      {children}
    </Tooltip>
  );
};

const marks = [
  { value: 0, label: "00:00" },
  {
    value: 360,
    label: "6:00",
  },
  {
    value: 720,
    label: "12:00",
  },
  {
    value: 1080,
    label: "18:00",
  },
  {
    value: 1439,
    label: "23:59",
  },
];

const CustomSlider = styled(Slider)({
  "& .MuiSlider-mark": {
    backgroundColor: "white",
    width: "3px",
  },
  "& .MuiSlider-markLabel": {
    color: "grey",
  },
});

export default function App() {
  const [serverData, setServerData] = useState<Record<Environment, Array<CombinedData>>>();
  const [apiData, setApiData] = useState<Record<Environment, string>>();
  const [refreshText, setRefreshText] = useState<string>("Updating...");
  const [timeRemaining, setTimeRemaining] = useState<number>(DEFAULT_COUNTER);
  const [automaticRefresh, setAutomaticRefresh] = useState<boolean>(true);
  const [time, setTime] = useState<Date>(new Date());

  const getAllData = useCallback(async () => {
    const serverResponse = await axios.get("/api/cobrowsing_server");
    const apiResponse = await axios.get("/api/api_status");

    return { cobrowsingData: serverResponse.data.data, apiData: apiResponse.data };
  }, []);

  const getRedisData = useCallback(async (time: Date) => {
    const timestamp = Math.floor(time.getTime() / 60_000);
    const serverResponse = await axios.get(`/api/cobrowsing_server/${timestamp}`);
    const apiResponse = await axios.get(`/api/api_status/${timestamp}`);

    return { cobrowsingData: serverResponse.data.data, apiData: apiResponse.data };
  }, []);

  const refresh = useCallback(async () => {
    setRefreshText("Updating...");
    const res = await getAllData();
    setServerData(res.cobrowsingData);
    setApiData(res.apiData);
    setRefreshText("Refresh");
    setTimeRemaining(DEFAULT_COUNTER);
    setTime(new Date());
  }, [getAllData]);

  const onRefreshClick = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();

      refresh();
      setTimeRemaining(DEFAULT_COUNTER);
    },
    [refresh],
  );

  function onSwitchChange() {
    setAutomaticRefresh((prev) => !prev);
  }

  const onDateChange = (value: DatePickerValue) => {
    setAutomaticRefresh(false);

    value!.getTime() < new Date().getTime() ? setTime(value!) : setTime(new Date());
  };

  const onHourChange = (value: string) => {
    setAutomaticRefresh(false);
    const newValue = new Date(time.getFullYear(), time.getMonth(), time.getDate(), parseInt(value), time.getMinutes());
    newValue.getTime() < new Date().getTime() ? setTime(newValue) : setTime(new Date());
  };

  const onMinuteChange = (value: string) => {
    setAutomaticRefresh(false);
    const newValue = new Date(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), parseInt(value));
    newValue.getTime() < new Date().getTime() ? setTime(newValue) : setTime(new Date());
  };

  function handleSliderChange(event: Event, value: number | number[]): void {
    setAutomaticRefresh(false);

    if (typeof value === "number") {
      const newValue = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        parseInt(convertNumberToTime(value).split(":")[0]),
        parseInt(convertNumberToTime(value).split(":")[1]),
      );
      newValue.getTime() < new Date().getTime() ? setTime(newValue) : setTime(new Date());
    }
  }

  const onSliderChange = debounce((event: Event, value: number | number[]) => handleSliderChange(event, value));

  useIntervalEffect(
    () => {
      setTimeRemaining((prev) => prev - 1);
    },
    automaticRefresh ? 1000 : undefined,
  );

  useEffect(() => {
    if (Math.floor(time.getTime() / 60_000) !== Math.floor(new Date().getTime() / 60_000)) {
      getRedisData(time).then((res) => {
        setServerData(res.cobrowsingData);
        setApiData(res.apiData);
      });
      setRefreshText("Refresh");
    }
  }, [time, getRedisData]);

  useEffect(() => {
    if (timeRemaining === 0) refresh();
  }, [timeRemaining, refresh]);

  useEffect(() => {
    getAllData().then((res) => {
      setServerData(res.cobrowsingData);
      setApiData(res.apiData);
      setTime(new Date());
    });
    setRefreshText("Refresh");
  }, [getAllData]);

  return (
    <div className="bg-cover min-h-screen bg-white transition-all dark:bg-gray-950 p-4 sm:p-10">
      <Card className="p-0">
        <div className="p-6 flex justify-between">
          <h3 className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Upscope - Server monitor
          </h3>
          {serverData && (
            <div className="block md:flex md:items-center left-0 md:justify-end">
              <div className="lg:flex lg:items-center lg:space-x-3">
                <div className="hidden items-center space-x-2 lg:flex">
                  <label
                    htmlFor="show-active-spaces"
                    className="whitespace-nowrap text-tremor-default text-tremor-content dark:text-dark-tremor-content"
                  >
                    Automatic refresh
                  </label>
                  <Switch
                    checked={automaticRefresh}
                    onChange={onSwitchChange}
                    id="Automatic refresh"
                    name="Automatic refresh"
                  />
                </div>
                <span className="hidden h-8 w-px bg-tremor-border dark:bg-dark-tremor-border lg:block" />
                <button
                  onClick={(event) => onRefreshClick(event)}
                  type="button"
                  className="mt-2 h-9 w-full whitespace-nowrap rounded-tremor-small bg-tremor-brand px-4 text-tremor-default font-medium
                    text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis disabled:hover:bg-tremor-brand
                    dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input
                    dark:hover:bg-dark-tremor-brand-emphasis sm:block md:mt-0 md:w-fit"
                >
                  {timeRemaining >= 1 && automaticRefresh && refreshText === "Refresh"
                    ? `${refreshText} (${timeRemaining})`
                    : refreshText}
                </button>
              </div>
            </div>
          )}
        </div>
        {serverData && apiData && (
          <TabGroup>
            <div className="flex justify-between flex-wrap">
              <TabList className="px-6">
                <EnvironmentTab key="production" environment="production" apiData={apiData} />
                <EnvironmentTab key="staging" environment="staging" apiData={apiData} />
              </TabList>
              <div className="flex-1 flex space-x-4 px-6 w-full items-center flex-wrap">
                <Box sx={{ flex: "auto" }}>
                  <CustomSlider
                    aria-label="Small steps"
                    defaultValue={convertTimeToNumber(time.getHours().toString() + ":" + time.getMinutes().toString())}
                    step={1}
                    min={0}
                    max={1439}
                    value={convertTimeToNumber(time.getHours().toString() + ":" + time.getMinutes().toString())}
                    valueLabelDisplay="on"
                    components={{
                      ValueLabel: ValueLabelComponent,
                    }}
                    onChange={onSliderChange}
                    marks={marks}
                  />
                </Box>
                <div className="flex flex-wrap">
                  <DatePicker
                    className="mx-auto max-w-sm w-24 px-2"
                    value={time}
                    maxDate={new Date()}
                    minDate={new Date(Date.now() - 24 * 60 * 60 * 1000 * 6)}
                    onValueChange={onDateChange}
                    enableClear={false}
                  />
                  <SearchSelect
                    className="mx-auto w-16 px-2"
                    defaultValue={time.getHours().toString()}
                    value={time.getHours().toString()}
                    onValueChange={onHourChange}
                  >
                    {new Array(24).fill(0).map((_, index) => (
                      <SearchSelectItem key={index} value={index.toString()}>
                        {index.toString().padStart(2, "0")}
                      </SearchSelectItem>
                    ))}
                  </SearchSelect>
                  <SearchSelect
                    className="mx-auto w-16 px-2"
                    defaultValue={time.getMinutes().toString()}
                    value={time.getMinutes().toString()}
                    onValueChange={onMinuteChange}
                  >
                    {new Array(60).fill(0).map((_, index) => (
                      <SearchSelectItem key={index} value={index.toString()}>
                        {index.toString().padStart(2, "0")}
                      </SearchSelectItem>
                    ))}
                  </SearchSelect>
                </div>
              </div>
            </div>
            <TabPanels className="pt-2">
              {Object.entries(serverData).map(([environment, data]) => (
                <TabPanel key={environment} className="space-y-4 px-6 pb-6 pt-2">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {data.map((region) => (
                      <RegionCard key={region.mainResults.region} region={region} />
                    ))}
                  </div>
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        )}
      </Card>
    </div>
  );
}
