import {
  RiCheckboxCircleLine,
  RiCpuLine,
  RiErrorWarningLine,
  RiGroupLine,
  RiServerLine,
  RiTimerLine,
} from "@remixicon/react";
import { Card, List, ListItem } from "@tremor/react";
import { CombinedData } from "src/utils/types";

import WorkerBox from "./WorkerBox";

export default function RegionCard({ region }: { region: CombinedData }) {
  return (
    <Card className="rounded-tremor-small p-4">
      <div className="flex items-center space-x-2">
        <h4 className="truncate text-sm font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
          <a className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden={true} />
            {region.mainResults.region}
          </a>
        </h4>

        <span
          className="inline-flex items-center rounded bg-purple-100 px-1.5 py-0.5 text-xs font-medium text-purple-800 dark:bg-purple-400/10
            dark:text-purple-400"
        >
          {region.dataCenterName}
        </span>
        <span
          className="inline-flex items-center rounded bg-emerald-100 px-1.5 py-0.5 text-xs font-medium text-emerald-800
            dark:bg-emerald-400/10 dark:text-emerald-400"
        >
          {region.mainResults.status}
        </span>
      </div>
      <List className="mt-3 flex flex-wrap justify-between divide-none">
        <div>
          <ListItem className="justify-start space-x-2 py-1">
            <span>Sessions: </span>
            <span className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {region.mainResults.results.stats.session}
            </span>
          </ListItem>
          <ListItem className="justify-start space-x-2 py-1">
            <span>Proxy: </span>
            <span
              className="inline-flex items-center gap-1.5 rounded-tremor-small px-2 py-1 text-xs font-medium text-tremor-content-strong ring-1
                ring-inset ring-tremor-ring dark:text-dark-tremor-content-strong dark:ring-dark-tremor-ring"
            >
              {region.mainResults.region !== region.secondaryResults.region ? (
                <span
                  className="h-2 w-2 shrink-0 rounded-sm bg-emerald-300 dark:bg-dark-tremor-brand dark:bg-emerald-400"
                  aria-hidden={true}
                />
              ) : (
                <span
                  className="h-2 w-2 shrink-0 rounded-sm bg-tremor-content-subtle dark:bg-orange-600"
                  aria-hidden={true}
                />
              )}

              {region.mainResults.region !== region.secondaryResults.region ? "ok" : "not ok"}
            </span>
          </ListItem>
          <ListItem className="justify-start space-x-2 py-1">
            <span>Redis: </span>
            <span className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {region.mainResults.results.services.redis ? (
                <RiCheckboxCircleLine
                  className="h-4 w-4 text-emerald-400 dark:text-dark-tremor-content-subtle dark:text-emerald-400"
                  aria-hidden={true}
                />
              ) : (
                <RiErrorWarningLine
                  className="h-4 w-4 text-orange-600 dark:text-dark-tremor-content-subtle dark:text-orange-600"
                  aria-hidden={true}
                />
              )}
            </span>
          </ListItem>
          <ListItem className="justify-start space-x-2 py-1">
            <span>Database: </span>
            <span className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {region.mainResults.results.services.database ? (
                <RiCheckboxCircleLine
                  className="h-4 w-4 text-emerald-400 dark:text-dark-tremor-content-subtle dark:text-emerald-400"
                  aria-hidden={true}
                />
              ) : (
                <RiErrorWarningLine
                  className="h-4 w-4 text-orange-600 dark:text-dark-tremor-content-subtle dark:text-orange-600"
                  aria-hidden={true}
                />
              )}
            </span>
          </ListItem>
        </div>
        <WorkerBox region={region} />
      </List>

      <div className="mt-5 flex flex-wrap gap-4">
        <div className="flex items-center space-x-1.5">
          <RiGroupLine
            className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
            aria-hidden={true}
          />
          <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
            {region.mainResults.results.stats.online}
          </span>
        </div>
        <div className="flex items-center space-x-1.5">
          <RiServerLine
            className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
            aria-hidden={true}
          />
          <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
            {region.mainResults.results.stats.servers_count} + {region.secondaryResults.results.stats.servers_count}
          </span>
        </div>
        {region.mainResults.results.stats.server.cpu_load < 0.8 ? (
          <div className="flex items-center space-x-1.5">
            <RiCpuLine
              className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />
            <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
              {Math.round(region.mainResults.results.stats.server.cpu_load * 100)}%
            </span>
          </div>
        ) : (
          <div className="flex items-center space-x-1.5">
            <RiCpuLine className="h-4 w-4 text-orange-600 dark:text-orange-600" aria-hidden={true} />
            <span className="text-xs font-medium text-orange-600 dark:text-orange-600">
              {Math.round(region.mainResults.results.stats.server.cpu_load * 100)}%
            </span>
          </div>
        )}
        {region.mainResults.results.stats.server.wait_time < 1500 ? (
          <div className="flex items-center space-x-1.5">
            <span>
              <RiTimerLine
                className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
                aria-hidden={true}
              />
            </span>

            <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
              {region.mainResults.results.stats.server.wait_time}ms
            </span>
          </div>
        ) : (
          <div className="flex items-center space-x-1.5">
            <RiTimerLine className="h-4 w-4 text-orange-600 dark:text-orange-600" aria-hidden={true} />
            <span className="text-xs font-medium text-orange-600 dark:text-orange-600">
              {region.mainResults.results.stats.server.wait_time}ms
            </span>
          </div>
        )}
      </div>
    </Card>
  );
}
