// Tremor Raw cx [v0.0.0]

import { useMemo } from "react";
import clsx, { type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cx(...args: ClassValue[]) {
  return twMerge(clsx(...args));
}

export function useTextMeasure(className: string, text?: string) {
  const measure = useMemo(() => (text ? textToPx(className, text) : 0), [text, className]);

  return measure;
}

function textToPx(className: string, text: string) {
  const span = document.createElement("span");
  span.style.visibility = "hidden";
  if (!className.includes("whitespace-")) span.style.whiteSpace = "nowrap";

  span.className = className;
  span.textContent = text;
  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  let textWidth = Math.ceil(width);
  if (text.startsWith(" ")) textWidth += 5;

  if (text.endsWith(" ")) textWidth += 5;

  return textWidth;
}
export function convertNumberToTime(value: number): string {
  let hours = Math.floor(value / 60).toString();
  let minutes = (value % 60).toString();

  hours = hours.length === 1 ? "0" + hours : hours;
  minutes = minutes.length === 1 ? "0" + minutes : minutes;

  return `${hours}:${minutes}`;
}

export function convertTimeToNumber(time: string): number {
  const [hours, minutes] = time.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  timeout: number = 300,
): (...args: Parameters<T>) => void {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
