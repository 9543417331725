import { Tab } from "@tremor/react";

import { Environment } from "../../../shared/types";

export function EnvironmentTab({
  environment,
  apiData,
}: {
  environment: Environment;
  apiData: Record<Environment, string>;
}) {
  return (
    <Tab className="pb-2.5 font-medium hover:border-gray-300">
      <span className="ui-selected:text-tremor-content-strong ui-selected:dark:text-dark-tremor-content-strong">
        {environment}
      </span>
      {apiData[environment] === "ok" ? (
        <span
          className="ml-2 hidden rounded-tremor-small bg-tremor-background px-2 py-1 text-s font-semibold tabular-nums ring-1 ring-inset
            ring-tremor-ring text-emerald-800 dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:text-emerald-400
            sm:inline-flex"
        >
          {
            {
              production: `api: ${apiData[environment]}`,
              staging: `api: ${apiData[environment]}`,
            }[environment]
          }
        </span>
      ) : (
        <span
          className="ml-2 hidden rounded-tremor-small bg-tremor-background px-2 py-1 text-s font-semibold tabular-nums ring-1 ring-inset
            ring-tremor-ring text-orange-600 ui-selected:text-orange-600 dark:bg-dark-tremor-background dark:ring-dark-tremor-ring
            ui-selected:dark:text-dark-tremor-content-emphasis sm:inline-flex"
        >
          {
            {
              production: `api: not ok`,
              staging: `api: not ok`,
            }[environment]
          }
        </span>
      )}
    </Tab>
  );
}
