import { useEffect } from 'react';
import { useSyncedRef } from '../useSyncedRef/index.js';
export function useIntervalEffect(callback, ms) {
    const cbRef = useSyncedRef(callback);
    useEffect(() => {
        if (!ms && ms !== 0) {
            return;
        }
        const id = setInterval(() => {
            cbRef.current();
        }, ms);
        return () => {
            clearInterval(id);
        };
    }, [ms]);
}
