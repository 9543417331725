import { RiArmchairLine, RiLoader4Line, RiTimeLine, RiToolsLine } from "@remixicon/react";
import { ListItem } from "@tremor/react";
import { CombinedData } from "src/utils/types";

export default function WorkerBox({ region }: { region: CombinedData }) {
  return (
    <span
      className="items-center gap-1.5 rounded-tremor-small px-2 py-1 text-xs font-medium text-tremor-content-subtle ring-1 ring-inset
        ring-purple-400 dark:text-dark-tremor-content-subtle dark:ring-purple-400"
    >
      <span className="text-purple-400 dark:text-purple-400">Workers:</span>
      {region.mainResults.results.stats.server.workers.map((worker) => (
        <ListItem key={worker[0]} className="flex-wrap justify-start space-x-2 py-1">
          <span>{worker[0]}: </span>

          <div
            className="inline-flex items-center gap-1.5 rounded-tremor-small px-2 py-1 text-xs font-medium text-tremor-content-strong ring-1
              ring-inset ring-tremor-ring dark:text-dark-tremor-content-strong dark:ring-dark-tremor-ring"
          >
            <RiToolsLine
              className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />

            <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
              {worker[1].workers}
            </span>
            <RiArmchairLine
              className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />
            <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
              {worker[1].idle}
            </span>
            <RiLoader4Line
              className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />
            <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
              {worker[1].waiting}
            </span>
            <RiTimeLine
              className="h-4 w-4 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />
            <span className="text-xs font-medium text-tremor-content dark:text-dark-tremor-content">
              {worker[1].wait_time}
            </span>
          </div>
        </ListItem>
      ))}
    </span>
  );
}
